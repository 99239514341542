.portrait-navbar {
  font-family: "Bodoni Moda", serif !important;
}
.portrait-navbar-main {
  position: fixed;
  /* bottom: 0; */
  width: 100%;
  height: 60px;
  z-index: 20;
  top: 3vw;
}
.bottom-items-right {
  display: flex;
  color: black;
  text-align: center;
  /* padding: 0 17px 17px 17px; */
  margin: auto;
  margin-right: 0;
  list-style-type: none;
  float: right;
  position: absolute;
  margin-top: 0;
  right: 40px;
}
.bottom-items-left {
  width: 20%;
}
.right-item {
  float: right;
  font-size: 12px;
  font-family: "Montserrat", sans-serif;

  color: black;
  text-align: center;
  padding: 14px;
  text-decoration: none;
  display: table-cell;
}
/* .right-item img{
  width: 25px
} */
.left-item {
  float: left;
  font-size: 12px;
  font-family: "Montserrat", sans-serif;

  color: black;
  text-align: center;
  text-decoration: none;
  display: table-cell;
}
.portrait-key {
  width: 30px;
}
.bottom-menu-2 {
  display: grid !important;
  float: right;
  background: white;
  width: 100%;
  background-repeat: no-repeat;
  height: 0;
  position: fixed;
  right: 0;
  top: 9vh;
  opacity: 0;
  z-index: 19;
}
.bottom-title {
  position: absolute;
  top: -57px;
  display: none;
}
.portrait-main-img {
  height: 55%;
}
.portrait-main-img img {
  /* margin-left: 17vw; */
  left: 50%;
  transform: translate(-50%, 0) !important;
  -ms-transform: translate(-50%, 0) !important;
  -webkit-transform: translate(-50%, 0);
  justify-content: center;
  position: absolute;
  top: 81px;
  height: 40%;
}
.portrait-menu-context {
  position: absolute;
  width: 100%;
  list-style-type: none;
  height: 40%;
  bottom: 1vh;
  left: 11vw;
}
.portrait-menu-context li {
  font-size: 16px;
  margin-bottom: 3.4vh;
  border-bottom: 0.5px solid #dd7130;
}
.portrait-links {
  position: fixed;
  top: 0px;
  justify-content: center;
  display: flex;
  width: 100%;
  height: 55px;
}
.back-link {
  float: left;
  position: relative;
  left: -95px;
  margin-left: 0px;
  margin: auto;
  width: 50%;
  border-bottom: 0.5px solid #dd7130;
}
.back-link div {
  position: absolute;
  right: -55px;
  top: -11px;
}
.view-all {
  float: left;
  margin: auto;
  position: relative;
  /* right: -93px; */
  color: #dd7130;
  width: 50%;
  top: 4vw;
  /* border-bottom: 0.5px solid #DD7130; */
}
.jwl-ul {
  position: relative;
  list-style-type: none;
  overflow-y: hidden;
  overflow-x: hidden;
  width: 100%;
  height: 90%;
  padding: 0;
}
.jwl-ul li {
  margin-bottom: 3vh;
  text-align: center;
}
.jwl-ul li img {
  max-height: 13vh;
}
.left-item-mobile-text {
  position: absolute;
  left: 48vw;
}
.right-item-mobile-text {
  position: absolute;
  left: 5vw;
}
.noSelect {
  color: black;
}

.normal-menu {
  position: absolute;
  width: 40px !important;
  max-width: 20px;
  right: 5vw;
  top: 3vw;
}

.closed-menu {
  position: absolute;
  width: 30px !important;
  display: none;
  top: 15px;
}
.right-item img {
  width: 20px;
}
.button-text-menu {
  text-align: center;
  margin-top: -2vh;
}
.button-line-menu {
  width: 50%;
  border-bottom: 1px solid black;
  position: absolute;
  bottom: 10px;
  z-index: -1;
}
.button-text-menu p span {
  background: white;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 5px;
  font-family: "Bodoni Moda", serif !important;
}
.line-left {
  margin-left: 50%;
  margin-right: 50%;
}
.menu-portrait-div {
  position: absolute;
  padding: 30px;
  right: -8vw;
  top: 0vw;
}
.closed-portrait-div {
  position: absolute;
  padding: 30px;
  right: -9vw;
}
@media only screen and (max-width: 1200px) {
  .navigation-main {
    display: none;
  }
  .portrait-navbar-main {
    display: flex;
  }
  .portrait-links {
    display: flex;
  }
}
@media only screen and (min-width: 1200px) {
  .navigation-main {
    display: block;
  }
  .portrait-navbar-main {
    display: none;
  }
  .portrait-links {
    display: none;
  }
}
@media only screen and (max-width: 400px) {
  .portrait-menu-context li {
    font-size: 13px;
  }
}
@media only screen and (min-width: 540px) {
  .portrait-menu-context {
    left: 23vw;
  }
}
@media only screen and (min-width: 750px) {
  .portrait-menu-context {
    position: absolute;
    width: 100%;
    list-style-type: none;
    height: 40%;
    bottom: 4vh;
    left: 26vw;
  }
  .portrait-menu-context li {
    font-size: 26px;
    margin-bottom: 4.1vh;
    border-bottom: 0.5px solid #dd7130;
  }
}
@media only screen and (min-width: 540px) and (max-width: 600px) {
  .menu-portrait-div {
    position: absolute;
    padding: 30px;
    right: -6vw;
    top: 0vw;
  }
  .normal-menu {
    position: absolute;
    width: 40px !important;
    max-width: 20px;
    right: 4vw;
    top: 2vw;
  }
}
@media only screen and (min-width: 1000px) and (max-width: 1024px) {
  .bottom-menu-2 {
    top: 7vh;
  }
  .portrait-navbar-main {
    top: 0;
    left: -7vw;
  }
  .closed-menu {
    right: -3vw;
    top: 4vw;
  }
  .normal-menu {
    position: absolute;
    width: 40px !important;
    max-width: 20px;
    right: 2vw;
    top: 1vw;
  }
  .menu-portrait-div {
    position: absolute;
    padding: 30px;
    right: -4vw;
    top: 2vw;
  }
  .bottom-menu-2 {
    top: 8vh;
  }
}
@media only screen and (min-width: 750px) and (max-width: 800px) {
  .portrait-navbar-main {
    top: 0;
  }
  .closed-menu {
    right: -3vw;
    top: 4vw;
  }
  .normal-menu {
    position: absolute;
    width: 40px !important;
    max-width: 20px;
    right: 2vw;
    top: 1vw;
  }
  .menu-portrait-div {
    position: absolute;
    padding: 30px;
    right: -5vw;
    top: 2vw;
  }
  .bottom-menu-2 {
    top: 8vh;
  }
}
