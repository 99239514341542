.beyond {
  display: flex;
  padding: 0 !important;
}
.beyond-container {
  width: 60%;
}
.beyond-wrapper {
  margin-top: -100px;
}
.beyond-header {
  margin-top: 45%;
  /* width: 60%; */
  width: 80%;
  margin-left: 10%;
}
.beyond-image {
  width: 40%;
}
.beyond-header-child-1 {
  font-size: 24px;
  font-family: "Montserrat", sans-serif;
}
.beyond-header-child-2 {
  font-size: 9vw;
  color: #6f6cb8;
  font-family: "HenriDidot" !important;
  -webkit-text-stroke-width: 0.2px;
  -webkit-text-stroke-color: #6f6cb8;
}
.beyond-header-child-2 span {
  color: #53acaf;
  font-family: "HenriDidot" !important;
  -webkit-text-stroke-width: 0.2px;
  -webkit-text-stroke-color: #53acaf;
}
.beyond-header-child-3 {
  font-size: 24px;
  font-family: "Montserrat", sans-serif;

  float: right;
}
.beyond-button {
  float: right;
  position: relative;
  left: 4vw;
  width: 100%;
  border-bottom: 0.5px solid #dd7130;
  margin-top: 8.5vw;
}
.beyond-button a {
  position: absolute;
  font-size: 20px;
  right: -12.5vw;
  font-family: "Montserrat", sans-serif !important;
  top: -1.6vw;
  color: gray;
}
.beyond-button a span {
  color: #cf4520;
}

.beyond-button a:after {
  background: none repeat scroll 0 0 transparent;
  border-bottom: 1px #cf4520;
  bottom: 0;
  content: "";
  display: block;
  height: 0.5px;
  left: 50%;
  opacity: 0.5;
  background: #cf4520;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}
.beyond-button a:hover:after {
  width: 100%;
  left: 0;
  color: #cf4520;
}
.beyond-button a:hover {
  color: #cf4520;
  transition: 0.3s;
}

.bridal-collection-button-2 {
  float: left;
  position: relative;
  bottom: 0;
  right: -6vw;
  top: 13vw;
  margin-left: 0px;
  margin: auto;
  width: 100%;
  /* border-bottom: 0.5px solid #dd7130; */
  margin-top: -3.5vw;
  font-family: "Montserrat", sans-serif !important;
}
.bridal-collection-button-2 a {
  position: absolute;
  font-size: 20px;
  /* left: 38.5vw; */
  width: 12vw;
  top: -1.5vw;
  color: #928b82;
  text-align: left;
}
.bridal-collection-button-2 a span {
  color: #cf4520;
}
.bridal-collection-button-2 a:hover:after {
  width: 100%;
  left: 0;
  opacity: 1;

  color: #cf4520;
}
.bridal-collection-button-2 a:hover {
  color: #cf4520;
  opacity: 0.5;
  transition: 0.3s;
}

.bridal-button-border {
  width: 100%;
  border: 0.5px solid #c0c0c0;
}
.bridal-button-text {
  float: right;
}

@media only screen and (max-width: 1300px) {
  .beyond-wrapper {
    margin-top: 0;
  }
  .bridal-collection-button-2 {
    top: 26vw;
  }

  .bridal-collection-button-2 a {
    position: RELATIVE;
    font-size: 20px;
    /* font-family: "Montserrat", sans-serif !important; */
    left: 50vw;
    float: LEFT;
    color: #928b82;
    padding: 3vw;
    width: 50vw;
    top: -10vw;
  }
  .beyond {
    display: block;
  }
  .beyond-container {
    width: 100%;
  }
  .beyond-header {
    width: 80%;
    margin-top: 25%;
  }
  .beyond-header-child-1 {
    font-size: 4vw;
  }
  .beyond-header-child-2 {
    font-size: 16vw;
    color: #6f6cb8;
  }
  .beyond-header-child-3 {
    font-size: 4vw;
    float: left;
  }
  .beyond-image {
    width: 100%;
  }
  .beyond-button {
    float: l;
    position: relative;
    left: 4vw;
    width: 100%;
    border-bottom: 0.5px solid #dd7130;
    margin-top: 26.5vw;
  }
  .beyond-button a {
    position: absolute;
    font-size: 20px;
    right: -50.5vw;
    font-family: "Montserrat", sans-serif !important;
    top: -6.6vw;
    color: gray;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1200px) {
  .bridal-collection-button-2 a {
    font-size: 34px;
  }
  .bridal-collection-button-2 {
    right: 20vw;
  }
}
@media only screen and (min-width: 250px) and (max-width: 400px) {
  .bridal-collection-button-2 a {
    font-size: 16px;
  }
}
@media only screen and (min-width: 1000px) and (max-width: 1200px) {
  .bridal-collection-button-2 a {
    width: 40vw;
    font-size: 40px;
  }
}
