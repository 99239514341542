@font-face {
  font-family: "AmalfiCoast";
  src: local("AmalfiCoast"), url(./fonts/AmalfiCoast.ttf) format("truetype");
}
@font-face {
  font-family: "Hearthstone";
  src: local("Hearthstone"), url(./fonts/HearthStone.ttf) format("truetype");
}
@font-face {
  font-family: "OpenSans";
  src: local("OpenSans"), url(./fonts/OpenSans.ttf) format("truetype");
}
@font-face {
  font-family: "HenriDidot";
  src: local("HenriDidot"), url(./fonts/HenriDidot.otf) format("truetype");
}
@font-face {
  font-family: "Forum-Regular";
  src: local("Forum-Regular"), url(./fonts/Forum-Regular.otf) format("opentype");
}
body {
  overflow-x: hidden !important;
  overflow-y: hidden !important;
  margin: 0;
  width: 100% !important
  ;
}
html {
  overflow-x: hidden !important;
  overflow-y: hidden !important;

  width: 100% !important;
}

.mainAppBody {
  overflow-x: hidden !important;
  width: 100% !important;
  position: relative;
}
.faded {
  overflow-x: hidden !important;
  width: 100% !important;
  position: relative;
}
section {
  float: left;
  width: 100%;
  height: auto;
  padding: 50px 0;
  margin: 0;
}
* {
  box-sizing: border-box;
}
[class*="col-"] {
  float: left;
  padding: 5px;
  border: none;
}
.equal-col {
  margin-top: 3vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

/*  homepage   -------------------------------  */

.header {
  margin-bottom: 30px;
}

.birth-center {
  text-align: center;
}
.header {
  text-align: center;
}
.title-birthstones p {
  font-size: 30px !important;
}

.logo {
  max-width: 160px;
}

p {
  /* font-family: "OpenSans" !important; */
  font-family: "Montserrat", sans-serif !important;
}
h2 {
  font-family: "AmalfiCoast";
  font-weight: 300;
  font-size: 65px;
}

.section1 {
  float: left;
  width: 100%;
  height: auto;
  padding: 0 5vw;
  margin: 0;
  /* margin-top: 2vw; */
}
.section1 h2 {
  text-align: center;
}
.section1 p {
  font-size: 16px;
}
.section1 img {
  width: 100%;
  height: auto;
}
.col-1 {
  width: 100%;
}
.col-2 {
  width: 50%;
}
.col-3 {
  width: 30%;
}
.col-4 {
  width: 22%;
}
.col-5 {
  width: 48%;
}
.col-6 {
  width: 60%;
}

.col-7 {
  width: 40%;
}

.col-8 {
  width: 66%;
}
section.section1.collections {
  margin-top: 3vw;
  /* margin-top: 15vw; */
}
.videoAntonia {
  max-width: 100%;
  min-width: 100%;
}
.videoSoulmate {
  max-width: 100%;
  min-width: 100%;
}
.videoExclusive {
  max-width: 100%;
  min-width: 100%;
}
.videoDropthemic {
  max-width: 100%;
  min-width: 100%;
}
/*  ----------------------------------------------------------------- descktop  love story     */

.love-story {
  columns: 3 200px;
  width: 100%;
  margin: 0 auto;
}
.love-story div {
  width: 150px;
  padding-bottom: 15px;
  display: inline-block;
  width: 100%;
}
.love-story img {
  width: 100%;
}
.love-story h2 {
  text-align: center;
  font-size: 3vw;
  font-weight: 400;
}
.love-story p {
  text-align: center;
  font-size: 0.9vw;
  font-weight: 400;
  line-height: 1.2vw;
  padding-bottom: 2vw;
}
.grid-item-1,
.grid-item-5 {
  padding-top: 4.8vw;
}
.space-15 {
  margin-bottom: 15px;
}
.mobile-setup {
  display: none !important;
}

.no-padding {
  padding: 0 !important;
}

/*   ----------------------------------------------------------  zoom effect    */
.zoom {
  cursor: pointer;
  transition: transform 1.5s;
}

.zoom:hover {
  -ms-transform: scale(1.15); /* IE 9 */
  -webkit-transform: scale(1.15); /* Safari 3-8 */
  transform: scale(1.15);
}
@media only screen and (max-width: 1300px) {
  .zoom:hover {
    transform: none;
  }
}
.zoomsmall {
  cursor: pointer;
  transition: transform 1s;
}

.zoomsmall:hover {
  -ms-transform: scale(1.1); /* IE 9 */
  -webkit-transform: scale(1.1); /* Safari 3-8 */
  transform: scale(1.1);
}

/*  ----------------------------------------------------------------- descktop  separator1     */

.separator1 {
  width: 100%;
  /* margin: 4vw 0; */
  display: flex;
  align-items: center;
  justify-content: center;
}
.sep1 {
  background-position: center;
  background-repeat: no-repeat;
  /* height: 100vh; */
  background-size: 100vw auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 60px;
}
.separator1-item-1 {
  width: 50%;
  padding: 0;
  margin: 0 auto;
}
.separator1-item-2 {
  width: 19.66%;
  padding: 53px;
}
.mobil {
  display: none;
}

/*  ----------------------------------------------------------------- descktop  footer1     */

.news-center {
  text-align: center;
}
.footer-column-desktop {
  width: 25% !important;
  font-family: "Forum-Regular";
  float: left;
  display: none;
}
.footer-column-desktop ul {
  padding: 0;
  list-style-type: none;
}
.footer-column-desktop ul li {
  padding: 5px 0 5px 0;
  list-style-type: none;
}
.footer-column-desktop a {
  /* color: #fcfcfc; */
  color: #5b5b5b !important;

  font-size: 18px;
  /* font-family: "Forum-Regular" !important; */
}
.footer-utils {
  padding: 0px 0px 0px 40px;
  margin: 0px 0px 0px 20px;
}
.footer-utils a {
  opacity: 1;
}
.footer-utils a:hover {
  opacity: 0.5;
}
.footer-utils li {
  display: inline-block;
  margin-left: 25px;
}
.footer-utils img {
  width: auto !important;
  height: auto !important;
}
.footer-contacts img {
  width: auto !important;
  height: auto !important;
}
.footer-contacts {
  display: inline-block;
}
.footer-contacts p {
  display: inline-block;
  margin: 0px 0px 10px 20px !important;
  /* padding: 0px 20px 20px 0px !important; */
  /* color: #fcfcfc !important; */
  color: #5b5b5b !important;
  font-size: 18px;
  /* font-family: "Forum-Regular"!important; */
}
.title-footer-down {
  /* font-family: "OpenSans" !important; */
  font-family: "Montserrat", sans-serif !important;

  margin-top: 60px;
  padding: 0;
  line-height: 0;
  font-size: 22px !important;
  text-align: left !important;
  /* color: #fcfcfc !important; */
  color: #5b5b5b;
  /* font-family: "Forum-Regular"!important; */
}
.footer-column-desktop a:hover {
  color: white;
  /* opacity: 0.6; */
  transition: 0.4s;
}
.footer-down {
  background: linear-gradient(180deg, #e1e1e1, #c0c0c0);
}
.footer1 {
  width: 100%;
  margin: 0;
}
.footer-top {
  width: 100%;
  margin: 0;
  text-align: center;
}
.footer-top img {
  width: 65px;
  height: auto;
}
.footer-top p {
  font-size: 30px;
  padding: 30px;
}
.footer-top .button1,
.footer-bottom .button1 {
  /* font-family: "OpenSans"; */
  font-family: "Montserrat", sans-serif !important;

  font-size: 20px;
  width: 120px;
  padding: 8px 15px;
  border: 1px solid #eb863c;
  color: #777;
  font-weight: 600;
  margin: 20px auto;
  cursor: pointer;
}
.footer-top .button1:hover,
.footer-bottom .button1:hover {
  color: white;
  transition: all 0.8s ease-in-out;
}
.footer-grid-item {
  width: 33.3%;
  padding: 3px;
  float: left;
}
.footer-bottom {
  float: left;
  width: 100%;
  padding: 45px 0px;
}
.footer-bottom .button1 {
  width: 145px;
  font-weight: 600;
  text-align: center;
}
.footer-bottom .button1 span {
  font-weight: 400;
}
.bottom-logos {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;
  position: relative;
  text-align: center;
  width: 120%;
  margin-top: 100px;
  margin-left: -10%;
  margin-bottom: 60px;
  float: left;
  height: 150px;
  padding: 0 !important;
}
.teilor-main-link-bottom {
  width: 100px;
  align-items: center;
  padding: 85px;
  padding-bottom: 35px;
  padding-top: 0;
}
.teilor-main-link-bottom a {
  width: 100%;
  height: 100%;
}
.logo1 {
  z-index: 4;
  display: inline-block;
  position: relative;
  width: 100% !important;
  height: 100% !important;
}
.logo2 {
  z-index: 4;
  position: relative;
  right: 50px;

  display: inline-block;
  width: 100px !important;
  height: 100px !important;
}
.copy {
  /* font-family: "OpenSans"; */
  font-family: "Montserrat", sans-serif !important;
  text-transform: uppercase;
  font-size: 16px;
  padding: 20px 10px;
  width: 100%;
  text-align: center;
  float: left;
  color: #6f6f6f;
}

.center .slick-center img {
  outline: none;
  opacity: 1;
  transform: scale(1.3);
}

.center img {
  outline: none;
  opacity: 0.8;
  transition: all 1s ease;
  transform: scale(0.7);
}
.center .slick-center h1 {
  outline: none;
  opacity: 1;
  transform: scale(1);
  line-height: 0px;
}

.center h1 {
  outline: none;
  opacity: 0.8;
  transition: all 1s ease;
  transform: scale(0.5);
  line-height: 0px;
}

.center .slick-center p {
  transform: scale(1);
  opacity: 1;
}

.center p {
  transform: scale(0.1);
  opacity: 0;
  transition: all 0.5s ease;
}

.center h3 {
  outline: none;
  opacity: 0 !important;
  transition: all 1s ease;
  transform: scale(0.8);
  line-height: 0px;
}
.center .slick-center h3 {
  outline: none;
  opacity: 1 !important;
  transform: scale(1);
  line-height: 0px;
}
.center {
  outline: none;
}
.slick-slide {
  outline: none;
}
.slick-slide div {
  outline: none;
  text-align: center;
}

.center h1 {
  /* font-family: "OpenSans"; */
  font-family: "Montserrat", sans-serif !important
  
  ;

  color: #aeaeae;

  font-size: 35px;
  font-weight: 200;
}
.center h3 {
  font-family: "AmalfiCoast";
  color: black !important;

  font-size: 45px;
  font-weight: 200;
}
.titlu-categorie {
  color: black;
  text-align: center;
  font-size: 20px !important;
  font-weight: bold;
}

@media only screen and (max-width: 1190px) {
  p {
    font-size: 15px;
  }
  h2 {
    font-size: 35px;
  }
  .landing-col {
    width: 100% !important;
  }
  .center h1 {
    font-size: 25px !important;
  }
  .header {
    margin-bottom: 0px !important;
  }
  .separator1 {
    background: none !important;
    height: auto !important;
    display: block !important;
  }
  /* .collections{
    padding:0!important;
  } */
  .sep1 {
    background: none !important;
    height: auto !important;
    display: block !important;
    padding: 0 !important;
  }
  .separator1-item-1 {
    width: 100% !important;
    float: left !important;
    padding: 0 6vw !important;
  }

  .mobileView {
    position: relative;
    padding: 50px;
    top: 0;
    left: 0;
    margin-top: -3px !important;
    margin-bottom: 5vw !important;
    float: left;
  }
  .mobile-bg {
    position: relative;
    width: 140%;
    height: auto;
    margin-left: -50px;
    margin-top: -50px;
  }
  .separator1-mobil-1 {
    position: absolute;
    top: 15%;
    left: 15%;
    width: 33%;
    /* float: left; */
  }
  .separator1-mobil-2 {
    /* position: relative; */
    width: 45% !important;
    position: absolute;
    top: 30%;
    left: 42%;
    /* top: 30px;
    left: 30px; */
  }
  .separator1-mobil-3 {
    width: 33% !important;
    position: absolute;
    top: 70%;
    left: 15%;

    /* position: absolute; */
    /* float: bottom; */
  }
  .mobil {
    display: block !important;
    float: left !important;
    width: 100% !important;
  }
  .desc {
    display: none !important;
  }
  .footer-top p {
    font-size: 22px !important;
    padding: 30px 5px !important;
  }
  .col1 {
    width: 100%;
    float: left;
    display: inline;
    flex-direction: column;
  }
  .content,
  .col-5,
  .col-3,
  .col-4,
  [class*="col--"] {
    width: 100%;
    padding: 0px;
  }
  section1 {
    padding: 0;
  }
  section1 p {
    font-size: 15px;
  }
  section1 h2 {
    font-size: 25px;
  }

  /*   ----------------------------------------------------------  mobil love story    */
  .love-story {
    columns: 1 200px;
  }
  .grid-item-4 {
    margin-bottom: -20px;
  }
  .mobile-setup {
    display: inline !important;
    float: left;
    width: 100%;
  }
  .desktop-setup {
    display: none !important;
  }
  .love-story h2 {
    font-size: 30px;
    font-weight: 400;
  }
  .love-story p {
    font-size: 18px;
    font-weight: 400;
    line-height: 21px;
  }
  .title-birthstones p {
    font-size: 18px !important;
  }
}

@media only screen and (max-width: 950px) {
  .footer-utils {
    margin-left: -23px !important;
  }
  .footer-column-desktop {
    width: 50% !important;
  }
  /* .footer-down {
    margin-top: 60px;
  } */
}
@media only screen and (max-width: 850px) {
  .footer-column-desktop {
    width: 100% !important;
  }
}
@media only screen and (min-width: 1200px) {
  .increase-width {
    width: 35%;
  }
  .title-page-top {
    font-size: 3vw !important;
  }
}
@media only screen and (min-width: 1190px) {
  .mobileView {
    display: none;
  }
}
.mobile-reveal {
  display: none;
}
@media only screen and (max-width: 450px) {
  .news-slider {
    margin-bottom: 40px !important;
  }
  .button1 {
    width: 100% !important;
  }
  .mobile-cta-birth {
    padding-top: 20px;
  }
  .birth-mobile-spread {
    width: 150vw !important;
    margin-left: -25vw !important;
  }
  .titlu-categorie {
    margin-top: 5px;
    padding-left: 10%;
    padding-right: 10%;
    font-size: 15px !important;
  }
  .mobile-erase {
    display: none !important;
  }
  .mobile-reveal {
    display: block !important;
    margin-bottom: 0;
  }
  .birth-trilogy {
    margin-top: 15px;
  }
  .cta-colectie .button1 {
    width: 82vw !important;
  }
  .birth-center {
    margin-top: 81px;
  }
  .variable-width .slick-list {
    padding-left: 55px !important;
  }
  .slick-slider {
    margin-bottom: -50px !important;
  }
  .center .slick-center p {
    min-width: 240px;
    margin-left: -35px;
  }
  .center h3 {
    margin-left: 0px !important;
  }

  .center .slick-center h1 {
    min-width: 200px;
    margin-left: -15%;
  }

  .section1 p {
    text-align: center;
  }
  .bottom-logos {
    margin-left: -15%;
    width: 130%;
  }
}
a {
  text-decoration: none !important;
  -webkit-tap-highlight-color: transparent;
}
.article-titles {
  color: black !important;
  padding-left: 10px;
}
.section1 p {
  text-align: center;
}

.cta-colectie .button1 {
  /* font-family: "OpenSans"; */
  font-family: "Montserrat", sans-serif !important;

  font-size: 15px;
  width: 250px;
  padding: 8px 15px;
  border: 1px solid #cf4520;
  color: #cf4520;
  font-weight: 600;
  margin: 20px auto;
  cursor: pointer;
  text-align: center;
}
.cta-colectie .button1:hover {
  color: #000;
  transition: all 0.8s ease-in-out;
}
.cta-colectie {
  text-align: center;
}
iframe {
  min-width: 250px !important;
  max-height: 200px !important;
}

.news-slider .variable-width .slick-slide img {
  margin: 0px !important;
}
.news-slider .slick-track {
  display: flex !important;
  padding-left: 3%;
}
.news-slider .slick-slide div {
  padding-right: 10px;
}
.collections .col-3 {
  margin-right: 3.3%;
}
/*Changes-10mai----------*/
.img-categorie {
  margin: 0 !important;
}
.suggested {
  display: flex;
  margin-left: 16%;
}
.title-suggested {
  text-align: center;
  font-size: 25px !important;
  font-weight: 700;
}
.margin-reducer {
  margin-top: 15px !important;
}
.description-emerald-bold {
  font-weight: bold;
}
.underline-category {
  border-bottom: 1px solid #eb863c;
  padding-bottom: 3px;
}
.presentation-image img {
  width: 100%;
}
