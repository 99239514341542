.bracelets-main {
  width: 60%;
  margin-left: 20%;
  overflow: hidden;
}
@media only screen and (max-width: 1300px) {
  .bracelets-main {
    width: 100%;
    margin-left: 0;
    margin-top: 0;
  }
  /* .bracelets-content-title p {
    font-size: 40px !important;
  } */
}
