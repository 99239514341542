.earringsCatrinel-main {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.earrings-bg {
  width: 80%;
  height: auto;
  position: relative;
  float: left;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 0;
  height: 45vw;
  margin-bottom: 15vw;
  background-position-y: -23vw;
}
@media only screen and (max-width: 1300px) {
  .earrings-bg {
    width: 75vw;
    height: auto;
    /* position: absolute; */
    float: left;
    background-repeat: no-repeat;
    background-position-x: -16vw;
    margin-bottom: 25vw;
    background-size: 160%;
    z-index: 0;
    height: 120vw;
    background-position-y: 0;
  }
}
