.bg-div {
  background: white;
}

.component-header {
  text-align: center;
  text-transform: uppercase;
}
.component-header p {
  font-size: 6vw !important;
  /* font-family: "HenriDidot" !important; */
  font-family: "Bodoni Moda", serif !important;

  letter-spacing: 30px;
  padding-left: 30px;
  margin-bottom: 50px;
  margin-top: 50px;
}
.div-ref-scroll {
  margin-top: -50px;
  width: 100%;
  height: 2vw;
  position: absolute;
}
.neck-scroll {
  margin-top: -100px;
}
.component-content {
  text-align: center;
  width: 70%;
  margin-left: 15%;
  margin-bottom: 80px;
  margin-top: 0;
  font-size: 20px;
}
.component-content-title {
  text-align: center;
  margin-bottom: -3vw;
}
.component-content-title p {
  font-family: "AmalfiCoast" !important;
  font-size: 40px !important;
  margin-top: 0;
}
.component-content-text {
  color: #5e5e5e;
}
.bracelets-portrait-positioning {
  margin-top: -4vh;
}
.bracelets-portrait-positioning .button-text-menu {
  margin-top: -4vh;
}
.earrings-portrait-positioning {
  margin-top: -4vh;
}
.earrings-portrait-positioning .button-text-menu {
  margin-top: -2vh;
}
.headMain-link {
  position: fixed;
  width: 170px;
  height: 45px;
  /* background: green; */
  margin-left: calc(50% - 85px);
  margin-top: 2vw;
  z-index: 50;
}
@media only screen and (max-width: 1300px) {
  .component-content {
    margin-top: -15vw;
    margin-bottom: 10vw;
    width: 80%;
    margin-left: 10%;
  }
  /* .neck-scroll {
    margin-top: -50px;
  } */
  .component-header p {
    font-size: 9vw !important;
    letter-spacing: 5px;
    padding-left: 0;
  }
  .component-content p {
    font-size: 20px;
    margin: 0;
  }
  .component-content-title p {
    font-size: 7vw !important;
    line-height: 80px;
    margin-bottom: 20px;
    margin-top: 60px;
  }
}
/* ......SURFACE DUO....... */
@media only screen and (min-width: 520px) and (max-width: 560px) {
  .component-content-title p {
    font-size: 55px !important;
    margin-bottom: 40px;
    margin-top: 120px;
  }
}
/* ......IPAD....... */

@media only screen and (min-width: 750px) and (max-width: 800px) {
  .component-content-title p {
    font-size: 55px !important;
    margin-bottom: 60px;
    margin-top: 120px;
  }
}

/* ......IPAD PRO....... */

@media only screen and (min-width: 1000px) and (max-width: 1024px) {
  .component-content-title p {
    font-size: 55px !important;
    margin-bottom: 60px;
    margin-top: 120px;
  }
}
.component-images {
  width: 100%;
  text-align: center;
  display: flex;
}
.component-column {
  width: 33.3%;
}
.middle-content {
  /* width: 80%;
  margin-left: 10%; */
}
.middle-image {
  width: 80%;
  margin-left: 10%;
  margin-top: 0vw;
  background: linear-gradient(
    180.22deg,
    #c4c4c4 -100.89%,
    rgba(196, 196, 196, 0) 82.4%
  );
  height: 12vw;
}
.middle-video {
  width: 100%;
}
.middle-video img {
  width: 100%;
}
.left-content {
  display: flex;
  margin-top: 20%;
}
.left-image-1 {
  width: 45%;
  margin-left: 10%;
  margin-top: 15%;
}
.left-image-1 img {
  width: 80%;
  margin-left: 10%;
}
.left-image-2 {
  width: 55%;
}
.left-image-2 img {
  width: 80%;
  margin-left: 10%;
  height: 95%;
}
/* right images */
.right-content {
  display: flex;
  margin-top: 20%;
}
.right-image-1 {
  width: 55%;
}
.right-image-1 img {
  width: 80%;
  margin-right: 10%;
  height: 95%;
}
.right-image-2 {
  width: 45%;
  margin-top: 15%;
  margin-right: 10%;
}
.right-image-2 img {
  width: 80%;
  margin-right: 10%;
}
/* ///////////////////////////////// CATRINEL VIDEO //////////////////// */
.catrinel-main-video {
  width: 30vw;
  height: 30vw;
  background-color: aliceblue;
  position: absolute;
  right: 0vw;
  top: 20vw;
}
.catrinel-video-container {
  position: relative;
  width: 20%;
  float: right;
}
.catrinel-bg {
  width: 80%;
  height: auto;
  position: relative;
  float: left;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 0;
  height: 45vw;
  margin-bottom: 15vw;
  background-position-y: -73vw;
}

@media only screen and (max-width: 1300px) {
  .catrinel-main-video {
    width: 50vw;
    height: 50vw;
    background-color: aliceblue;
    position: absolute;
    right: 0vw;
    top: 95vw;
  }
  .catrinel-bg {
    width: 75vw;
    height: auto;
    /* position: absolute; */
    margin-bottom: 25vw;
    float: left;
    background-repeat: no-repeat;
    background-position-x: -30vw;
    background-size: 200%;
    z-index: 0;
    height: 120vw;
    background-position-y: -105vw;
  }
}

@media only screen and (max-width: 1300px) {
  .component-images {
    width: 100%;
    text-align: center;
    display: flex;
    margin-top: -4vw;
    margin-bottom: 10vw;
  }
  .middle-image {
    width: 80%;
    margin-left: 10%;
    height: 21vw;
    margin-top: 0vw;
  }
  .middle-image img {
    width: 100%;
    height: 33vw;
  }
  .middle-video {
    /* height: 33vw; */
    width: 100%;
  }
  .left-image-1 {
    margin-left: 0;
  }
  .left-image-1 img {
    margin-left: 0;
  }
  .right-image-2 {
    margin-right: 0;
  }
  .right-image-2 img {
    margin-right: 0;
  }
}
