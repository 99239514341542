.necklacesCatrinel-main {
  position: relative;
  overflow: hidden;
  width: 100%;
  background: white;
}
@media only screen and (max-width: 1300px) {
  .necklaces-bg {
    background-position-y: -107vw;
  }
}
