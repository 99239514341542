.headMain-header {
  width: 100%;
  padding: 20px;
  z-index: 1;
  background: white;
  position: fixed;
}
.headMain-link {
  z-index: 10;
}
.headMain-header img {
  max-width: 160px;
  margin-left: calc(50% - 80px);
}
.headMain-main {
  width: 100%;
  overflow: hidden;
  height: 88vw;
  position: relative;
}
.headMain-bg {
  opacity: 1;
  width: 100%;
  background-repeat: no-repeat;
  background-size: 85%;
  margin-left: 7.5%;
  height: auto;
  position: fixed;
  height: 100vh;
  z-index: -2;
  background-position-y: -60px;
  float: left;
}
.head-content {
  float: right;
  margin-right: 20%;
  margin-top: 16vw;
  margin-left: 55%;
  width: 25%;
  position: fixed;
  z-index: -2;
}
.head-title {
  font-size: 4vw;
  color: white;
  margin-bottom: 20px;
  line-height: 4.4vw;
  text-transform: uppercase;
  font-family: "Bodoni Moda", serif !important;
}
.head-second-title {
  font-size: 2vw;
  color: white;

  margin-bottom: 20px;
  font-family: "AmalfiCoast" !important;
}
.head-text p {
  color: white;

  font-size: 1.7vw !important;
}
.head-effect {
  width: 100%;
  /* position: relative; */
  height: 150vw;
  z-index: -1;
  margin-bottom: 0;
  /* background: linear-gradient(
359deg
, white 50%, transparent 95%); */
  background: linear-gradient(359deg, #fff 48%, rgba(255, 255, 255, 0) 65%);
}
.headMain-separator {
  background: white;
  position: relative;
  margin-top: -7vw;
  display: block;
}
.headMain-separator img {
  margin-left: calc(50% - 19px);
  display: block;
}
.pseudo-div {
  /* background-color: purple; */
  width: 100%;
  height: 50px;
  position: absolute;
  top: calc(100vh + 70px) !important;
  /* top: calc(100vh -) */
}
@media only screen and (max-width: 1000px) {
  .head-content {
    float: right;
    margin-right: 10%;
    margin-top: 18vw;
    margin-left: 60%;
    width: 35%;
    position: relative;
    z-index: 0;
  }
  .headMain-separator {
    display: block;
    padding-bottom: 30px;
    padding-top: 20px;
  }
  .headMain-header img {
    max-width: 130px;
    margin-left: calc(50% - 65px);
  }
  .headMain-main {
    width: 100%;
    overflow: hidden;
    height: 175vw;
    position: relative;
  }
  .headMain-bg {
    opacity: 1;
    display: block;
    width: 100%;
    background-repeat: no-repeat;
    background-size: 100%;
    height: auto;
    position: fixed;
    height: 100vh;
    z-index: -2;
    float: left;
    margin-left: 0;
    background-position-y: 65px;
  }
  .head-effect {
    width: 100%;
    height: 240vw;
    z-index: -1;
    margin-bottom: 0;
    background: linear-gradient(359deg, #fff 45%, rgba(255, 255, 255, 0) 65%);
  }
  .head-content {
    float: right;
    margin-right: 12.5%;
    margin-top: 102vw;
    margin-left: 12.5%;
    width: 75%;
  }
  .head-title {
    font-size: 11vw;
    color: #fff;
    margin-bottom: 20px;
    margin-left: 0;
    line-height: 10vw;
    font-family: "Bodoni Moda", serif !important;
  }
  .head-second-title {
    font-size: 6.7vw;
    color: #000;
    margin-bottom: 20px;
    font-family: "AmalfiCoast" !important;
  }
  .head-text p {
    color: black;
    font-size: 4vw !important ;
    text-align: center;
  }
  .headMain-separator img {
    margin-left: calc(50% - 7.5px);
    width: 15px;
    display: none;
  }
}
