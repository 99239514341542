.clubMain-main {
  width: 100%;
  overflow: hidden;
  position: relative;
}
.clubMain-content {
  margin-top: 60px;
}
.content-section-1 {
  width: 100%;
  /* margin-left: 5%; */
  position: relative;
  padding-top: 60px;
  padding-bottom: 60px;
  background-repeat: no-repeat;
  background-size: cover;
}
.section-1-logo {
  width: 100%;
  background-size: cover;
  background-position: center;
  text-align: center;
  /* margin-bottom: -45px; */
}
.section-1-logo img {
  width: 20vw;
}
.section-1-title {
  width: 100%;
  background-size: cover;
  background-position: 50%;
  text-align: center;
  font-family: "AmalfiCoast", sans-serif;
  font-size: 60px;
  color: #c9ac80;
  margin-top: -40px;
  margin-bottom: -20px;
}
.section-1-container {
  width: 50%;
  margin-left: 25%;
  display: flex;
}
.section-1-col-1 {
  width: 12.5%;
  position: relative;
  text-align: center;
}
.col-1-text {
  width: 100%;
  margin-top: 30px;
}
.col-1-button {
  width: 100%;
  padding: 15px;
  border: 1px solid #da6e5a;
  min-width: 220px;
  max-width: 220px;
  margin-left: calc(50% - 110px);
  margin-top: 20px;
}
.col-1-button a {
  color: #da6e5a;
}
.col-1-button a:hover {
  opacity: 0.5;
  transition: 0.3s;
}

/* ............col-2 */
.section-1-col-2 {
  width: 75%;
  position: relative;
  text-align: center;
}
.col-2-text {
  width: 100%;
  font-size: 30px;
}
.col-2-text a {
  color: black;
}
.col-2-text a:hover {
  opacity: 0.5;
  transition: 0.3s;
}
.col-3-text {
  width: 100%;
  margin-top: 30px;
}
.col-3-button {
  width: 100%;
  padding: 15px;
  border: 1px solid #da6e5a;
  min-width: 220px;
  max-width: 220px;
  margin-left: calc(50% - 110px);
  margin-top: 20px;
}
.col-3-button a {
  color: #da6e5a;
}
.col-3-button a:hover {
  opacity: 0.5;
  transition: 0.3s;
}
.col-2-separator {
  width: 100%;
}
.css-separator {
  /* border-left: 1px solid #da6e5a; */
  /* height: 50px; */
  margin-left: calc(50% - 0.5px);
  opacity: 0.2;
}
.section-1-col-3 {
  width: 12.5%;
  position: relative;
  text-align: center;
}
.arrow {
  border: solid #c9ac80;
  border-width: 0 2px 2px 0;
  display: inline-block;
  position: relative;
  top: 2px;
  left: 5px;
  padding: 9px;
}
.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}
@media only screen and (max-width: 1000px) {
  /* .section-1-title {
    height: 65px;
  } */
  /* .section-1-logo {
    margin-bottom: 0px;
  } */
  .section-1-title {
    margin-top: -20px;
    margin-bottom: -30px;
  }
  .section-1-logo img {
    width: 60vw;
  }
  .clubMain-main {
    width: 100%;
    overflow: hidden;
    height: fit-content;
    position: relative;
  }
  .section-1-container {
    width: 100%;
    margin-left: 0;
  }
  .section-1-col-1 {
    width: 12.5%;
  }
  .section-1-col-2 {
    width: 75%;
  }
  .section-1-col-3 {
    width: 12.5%;
  }
  /* .arrow {
    left: 100px;
  } */
}
/* @media only screen and (min-width: 750px) {
  .arrow {
    left: 160px;
  }
}
@media only screen and (min-width: 520px) and (max-width: 600px) {
  .arrow {
    left: 120px;
  }
} */
