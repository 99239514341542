.necklacesShop-container {
  width: 90%;
  margin-left: 5%;
}
.necklacesShop-main {
  overflow: hidden;
  width: 100%;
}
.necklacesShop-element {
  width: 30%;
  position: relative;
  float: left;
  margin-top: 3vw;
  margin-left: 1.5vw;
}
.necklacesShop-element img {
  width: 100%;
  position: relative;
}
.necklacesShop-video {
  width: 30%;
  float: left;
  height: 26.6vw;
  position: relative;
  /* margin-top: 6vw; */
}
@media only screen and (max-width: 1300px) {
  .necklacesShop-container {
    width: 100%;
    margin-left: 0;
  }
  .necklacesShop-element {
    width: 30%;
    position: relative;
    float: left;
    margin-top: 0vw;
    margin-left: 0;
  }
  .necklacesShop-video {
    width: 100%;
    height: 100vw;
    margin-bottom: 10vw;
  }
  .necklacesShop-product-1 {
    width: 50%;
  }

  .necklacesShop-product-2 {
    width: 50%;
  }

  .necklacesShop-product-3 {
    width: 100%;
  }

  .necklacesShop-product-4 {
    width: 50%;
  }

  .necklacesShop-product-5 {
    width: 50%;
  }
}
