.braceletsCatrinel-main {
  position: relative;
  overflow: hidden;
  width: 100%;
}
.bracelets-bg {
  width: 80%;
  height: auto;
  position: relative;
  float: left;
  background-repeat: no-repeat;
  background-position-y: -20vw;
  background-size: cover;
  z-index: 0;
  height: 45vw;
  margin-bottom: 15vw;
}
.bracelets-main-video {
  width: 30vw;
  height: 30vw;
  background-color: aliceblue;
  position: absolute;
  right: 0vw;
  top: 30vw;
}
.bracelets-video-container {
  position: relative;
  width: 20%;
  float: right;
}
@media only screen and (max-width: 1300px) {
  .bracelets-bg {
    width: 75vw;
    height: auto;
    /* position: absolute; */
    float: left;
    background-repeat: no-repeat;
    background-position-x: -27vw;
    background-position-y: -23vw;
    background-size: 160%;
    margin-bottom: 25vw;
    z-index: 0;
    height: 120vw;
  }
  .bracelets-main-video {
    width: 50vw;
    height: 50vw;
    background-color: aliceblue;
    position: absolute;
    right: 0vw;
    top: 95vw;
  }
}
