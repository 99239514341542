.braceletsShop-container {
  width: 90%;
  margin-left: 5%;
}
.braceletsShop-main {
  overflow: hidden;
  width: 100%;
}
.braceletsShop-element {
  width: 30%;
  position: relative;
  float: left;
  margin-top: 3vw;
  margin-left: 1.5vw;
}
.braceletsShop-element img {
  width: 100%;
  position: relative;
}
.braceletsShop-video {
  width: 30%;
  float: left;
  height: 26.6vw;
  position: relative;
}

@media only screen and (max-width: 1300px) {
  .braceletsShop-container {
    width: 100%;
    margin-left: 0;
  }
  .braceletsShop-element {
    width: 30%;
    position: relative;
    float: left;
    margin-top: 0vw;
    margin-left: 0;
  }
  .braceletsShop-video {
    width: 100%;
    height: 100vw;
    margin-bottom: 10vw;
  }
  .braceletsShop-product-1 {
    width: 50%;
  }

  .braceletsShop-product-2 {
    width: 50%;
  }

  .braceletsShop-product-3 {
    width: 100%;
  }

  .braceletsShop-product-4 {
    width: 50%;
  }

  .braceletsShop-product-5 {
    width: 50%;
  }
}

@media only screen and (max-width: 280px) {
  .bracelets-content-title p {
    font-size: 30px !important;
  }
  .component-content-title p {
    font-size: 30px !important;
  }
}
