.rings-main {
  width: 60%;
  overflow: hidden;

  margin-left: 20%;
}
@media only screen and (max-width: 1300px) {
  .rings-main {
    width: 100%;
    margin-left: 0;
    margin-top: -1px;
  }
}
