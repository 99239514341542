.component-button {
  float: left;
  position: relative;
  bottom: 0;
  margin-left: 0px;
  margin: auto;
  width: 64%;
  margin-top: 0;
  font-family: "Montserrat", sans-serif !important;
  margin-top: 70px;
  margin-bottom: 140px;
}
.component-button a {
  position: relative;
  text-transform: UPPERCASE;
  font-size: 16px;
  color: #cf4520;
  text-align: left;
}
.component-button a span {
  color: #cf4520;
}
.component-button a:hover:after {
  width: 100%;
  left: 0;
  opacity: 1;

  color: #cf4520;
}
.component-button a:hover {
  color: #cf4520;
  opacity: 0.5;
  transition: 0.3s;
}
.component-button-text {
  float: right;
  width: 30%;
  margin-left: 20px;
}
.component-button-border {
  width: 70%;
  border: 0.5px solid #c0c0c0;
  position: absolute;
  bottom: 11px;
  left: -2vw;
}

.shop-icon {
  position: absolute !important;
  width: 1.15vw !important;
  float: right;
  bottom: 0;
  right: 2vw;
  transition: 0.3s;
}
.shop-icon:hover {
  opacity: 0.5;
}
@media only screen and (max-width: 1300px) {
  .shop-icon {
    width: 2.5vw !important;
    right: 4vw;
  }

  .component-button a {
    position: absolute;
    font-size: 15px;
    width: 73vw;
    color: #cf4520;
    text-align: left;
  }

  .component-button {
    width: 38%;
    margin-top: 70px;
    margin-bottom: 100px;
  }
  .component-button-border {
    width: 70%;
    border: 0.5px solid #c0c0c0;
    position: absolute;
    bottom: -11px;
    left: -2vw;
  }
}
