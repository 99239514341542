.ringsCatrinel-main {
  position: relative;
  width: 100%;
  overflow: hidden;
  display: block;
  margin-bottom: 50px;
}
.ringsCatrinel-bg {
  width: 100%;
  height: auto;
  position: relative;
  float: left;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 60px;
}
.rings-video-container {
  position: relative;
  width: 20%;
  float: right;
}
.ringsCatrinel-separator {
  position: relative;
  display: block;
  margin-bottom: 60px;
}
.ringsCatrinel-separator img {
  margin-left: calc(50% - 19px);
  display: block;
}
.video-overlayer {
  position: absolute;
  width: 100%;
  height: 85%;
}
.placeholder {
  position: absolute;
  width: 107vw;
  margin-left: -3vw;
}
.play-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100px;
  height: 100px;
  margin-top: -50px;
  margin-left: -50px;
}
.rings-component-content {
  text-align: center;
  width: 62%;
  margin-left: 19%;
  margin-bottom: 20px;
  margin-top: 0;
  font-size: 20px;
}
@media only screen and (max-width: 1300px) {
  .catrinel-video {
    width: 100%;
    background-repeat: no-repeat;
    background-size: 100%;
    height: 53vw;
  }
  .video-overlayer {
    height: 35%;
  }
  .play-btn {
    top: 30vw;
  }
  .rings-component-content {
    text-align: center;
    width: 70%;
    margin-left: 15%;
    margin-bottom: 20px;
    margin-top: 0;
    font-size: 20px;
  }
  .rings-component-content {
    margin-top: 0;
  }
  .rings-component-title p {
    font-size: 40px !important;
  }
  .ringsCatrinel-separator {
    display: block;
  }
  .ringsCatrinel-bg {
    width: 100%;
    height: auto;
    /* position: absolute; */
    float: left;
    background-repeat: no-repeat;
    background-position-x: -180px;
    background-size: cover;
  }
  .ringsCatrinel-separator img {
    margin-left: calc(50% - 15px);
    width: 30px;
    display: none;
  }
}
