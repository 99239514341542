.necklaces-main {
  width: 60%;
  margin-left: 20%;
}
.necklace-video {
  width: 90%;
  margin-left: 5%;
}
@media only screen and (max-width: 1300px) {
  .necklaces-main {
    width: 100%;
    margin-left: 0;
  }
}
