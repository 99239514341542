.logoAnim {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  overflow-x: hidden;
  position: fixed;
  height: 40vw;
  width: 100%;
  z-index: 1111111111111;
  /* margin-bottom:180px ; */
  margin-top: -3vw;
}

/* ...................MOBILE POSITIONING.......................... */
@media only screen and (max-width: 1190px) {
  .logoAnim {
    margin-bottom: 120px !important;
    height: 140vw;
    top: -17vw;
    /* margin-top: -12vw !important; */
  }
  .loader svg {
    width: 80%;
  }
}
/* @media only screen and (min-width: 250px) and (max-width: 850px) {
  .logoAnim {
    top: -8vw;
  }
} */
@media only screen and (min-width: 540px) and (max-width: 540px) {
  .logoAnim {
    top: -8vw;
  }
}
@media only screen and (min-width: 750px) and (max-width: 800px) {
  .logoAnim {
    top: 0vw;
  }
}
@media only screen and (min-width: 1000px) and (max-width: 1024px) {
  .logoAnim {
    top: 0vw;
  }
}
/* ................................................................ */
.title-ref {
  /* background: purple; */
  height: auto;
  width: 100px;
  position: absolute;
  top: 50px;
  z-index: 7;
}
.slider__container {
  display: block;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  width: 60%;
  margin-top: calc(100% - 135vw);
  margin-bottom: 150px;
}
.slider__container:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}
.slider__container .slide__container {
  position: absolute;
  background: transparent;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  border: 1px solid #f0f0f0;
}
.slider__container .slide__container .slide {
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: #f8f8f8;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(-50%, 50%);
  transition: all 0.5s ease-in-out;
  border: 1px solid #c9c9c9;
}
.slider__container .slide__container.slide--active .slide {
  width: 150px;
  height: 150px;
}
@media only screen and (max-width: 500px) {
  .slider__container {
    width: 80%;
    margin-top: calc(100% - 135vw);
    margin-bottom: 150px;
  }
}

span {
  margin-top: 130%;
  min-width: 120px;
  text-align: center;
}

/*Slider Container*/
.logo-mark {
  height: 30px;
  overflow: visible;
}

.logo-type {
  height: 30px;
  overflow: visible;
}
.loader {
  height: 150px;
  text-align: center;

  opacity: 0;
  position: absolute;
  top: 30px;
  z-index: 4;
}
/* .loader.teilor-logo {
  overflow: visible;
} */
/* .loader.teilor-logo {
  margin-left: -10px;
} */

.loader svg {
  height: 150px;
  margin-left: -10px;
}
