.desktop-navbar {
  overflow: hidden;
  top: 66px;
  color: black;
  position: fixed;
  width: 1200px;
  margin-left: calc(50% - 600px);
  /* height: 415px; */
  z-index: 5;
  top: 1.8vw;
}

.desktop-navbar a {
  float: left;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  color: black;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}
.menu {
  display: flex;
  color: black;
  text-align: center;
  margin: auto;
  width: 1200px;
  margin-left: calc(50% - 600px);
  list-style-type: none;
  justify-content: center;
}
.menu-item {
  float: left;
  font-size: 12px;
  font-family: "Montserrat", sans-serif;

  color: black;
  text-align: center;
  padding: 0px 14px;
  text-decoration: none;
  display: table-cell;
}

.menu-item p {
  margin-right: 10px;
}
.menu.menu-left.menu-item {
  padding-left: 0;
}
.menu-right {
  display: flex;
  color: black;
  text-align: center;
  padding: 0 90px;
  margin: auto;
  margin-right: 0;
  list-style-type: none;
  float: right;
  position: relative;
  margin-top: 0;
}
.menu-left {
  display: flex;
  color: black;
  text-align: center;
  padding: 0 90px;
  margin: auto;
  margin-left: 0;
  list-style-type: none;
  position: relative;
  float: left;
  margin-top: 0;
}
.menu-left li {
  padding: 0 11px !important;
}
.menu-right li {
  padding: 0 11px !important;
}
.dropbtn {
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  background: none;
  border: none;
  outline: none;
  color: black;
  font-family: inherit;
  margin: 0;
  margin-top: 10px;
  cursor: pointer;
}
.dropbtn:after {
  background: none repeat scroll 0 0 transparent;
  border-bottom: 1px #cf4520;
  bottom: 0;
  content: "";
  display: block;
  height: 0.5px;
  left: 50%;
  opacity: 0.5;
  background: #cf4520;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}
.dropbtn:hover:after {
  width: 100%;
  left: 0;
  color: #cf4520;
}
.dropbtn:hover {
  color: #cf4520;
  transition: 0.3s;
}
.dropbtn a {
  font-size: 13px !important;
  padding: 0 !important;
  float: inherit !important;
}
@media only screen and (max-width: 1200px) {
  .desktop-navbar {
    display: none;
  }
}
